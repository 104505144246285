import React, { useState, useEffect } from 'react'
import Layout from '../../../components/Layout'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import * as api from '../../../services/api'
import RichTextEditor from './RichTextEditor'
import emptyValue from '../../../utils/RichTextEditorEmptyValue'
import { getArticles, updateArticles } from '../../../services/api'
import _ from 'lodash'

const AddArticle = () => {
  const [notificationMessage, setNotifcationMessage] = useState({})
  const [bodyText, setBodyText] = useState(emptyValue)
  const [allArticles, setAllArticles] = useState([])
  useEffect(() => {
    const getAllArticles = async () => {
      try {
        const { data } = await getArticles()
        if (data) {
          setAllArticles(data)
        }
      } catch (err) {
        if (err.message.match(/(403|400)/)) {
          err('You are not a user')
        } else err('somethingWrong')
      }
    }
    getAllArticles()
  }, [])

  /*   useEffect(() => {
    if (!getToken()) {
      navigate('/auth')
    }
  }) */
  const formik = useFormik({
    initialValues: {
      title: '',
      body: '',
      excerpt: '',
      author: '',
      category: '',
      publishDate: '',
      tags: [],
    },
    validationSchema: Yup.object({
      title: Yup.string().required('يجب اضافة عنوان المقال'),
      excerpt: Yup.string().max(400, 'لا يمكن أن يزيد طول المختصر عن ٤٠٠ حرف'),
      body: Yup.string()
        .min(400, '  يجب اضافة محتويات المقال وألا تقل عن ٤٠٠ حرف')
        .required('  يجب اضافة محتويات المقال وألا تقل عن ٤٠٠ حرف'),
      author: Yup.string().required('يجب اضافة المؤلف'),
      category: Yup.string().required('يجب اضافة الفئة'),
    }),
    onSubmit: async (
      article,
      { setSubmitting, setErrors, setStatus, resetForm }
    ) => {
      setNotifcationMessage({})

      try {
        await api.addArticle(article)
        resetForm()
        setBodyText(emptyValue)
        setNotifcationMessage({
          body:
            'تم حفظ المقال بنجاح. سنقوم بمراجعته ونشره على الموقع خلال ساعات.',
          type: 'success',
        })
      } catch (err) {
        setNotifcationMessage({
          body: 'حدث خطأ أثناء حفظ المقال. الرجاء المحاولة مجددا.',
          type: 'danger',
        })
      }
    },
  })

  const onBodyChange = (text) => {
    setBodyText(text)
    formik.setFieldValue('body', text.toString('markdown'))
  }

  return (
    <Layout>
      <form onSubmit={formik.handleSubmit}>
        <div className="section">
          <h1 className="title">اضافة مقال جديد</h1>
          <hr />
          <div className="field">
            <label className="label" htmlFor="title">
              عنوان المقال
            </label>
            <div className="control">
              <input
                name="title"
                className="input"
                type="text"
                {...formik.getFieldProps('title')}
              />
            </div>
            {formik.touched.title && formik.errors.title ? (
              <p className="help is-danger">{formik.errors.title}</p>
            ) : null}
          </div>

          <div className="field">
            <label className="label" htmlFor="body">
              {' '}
              المقال
            </label>
            <div style={{ direction: 'ltr', textAlign: 'center' }}>
              <RichTextEditor
                {...formik.getFieldProps('body')}
                name="body"
                value={bodyText}
                onChange={onBodyChange}
              />
            </div>
            {formik.touched.body && formik.errors.body ? (
              <div className="help is-danger">{formik.errors.body}</div>
            ) : null}
          </div>

          <div className="field">
            <label className="label" htmlFor="excerpt">
              مختصر المقال
            </label>
            <div className="control">
              <textarea
                name="excerpt"
                className="textarea"
                maxLength="400"
                placeholder="مختصر المقال في حدود ٤٠٠ حرف"
                {...formik.getFieldProps('excerpt')}
              />
            </div>
            {formik.touched.excerpt && formik.errors.excerpt ? (
              <div className="help is-danger">{formik.errors.excerpt}</div>
            ) : null}
          </div>

          <div>
            <select className="select" {...formik.getFieldProps('author')}>
              <option value={-1}>المؤلف</option>
              {_.uniqBy(
                allArticles.map((article) => article.author),
                (author) => author.id
              ).map((author) => (
                <option key={author.id} value={author.id}>
                  {author.name}
                </option>
              ))}
            </select>
          </div>

          <div>
            <select {...formik.getFieldProps('category')}>
              <option value={-1}>الفئة</option>
              {_.uniqBy(
                allArticles.map((article) => article.category),
                (category) => category.id
              ).map((category) => (
                <option key={category.id} value={category.id}>
                  {category.categoryName}
                </option>
              ))}
            </select>
          </div>

          <div id="checkbox-group">العلامات</div>
          <div role="group" aria-labelledby="checkbox-group">
            {_.uniqBy(
              _.flatten(allArticles.map((article) => article.tags)),
              (tag) => tag.id
            ).map((tag) => (
              <label key={tag.id}>
                <input
                  type="checkbox"
                  {...formik.getFieldProps('tags')}
                  value={tag.id}
                />
                {tag.tagName}
              </label>
            ))}
          </div>

          <div>
            <input
              type="datetime-local"
              {...formik.getFieldProps('publishDate')}
            />
          </div>
          <div className="field is-grouped">
            <div className="control">
              <button type="submit" className="button is-link">
                حفظ المقال
              </button>
            </div>
          </div>
          {notificationMessage.body && (
            <div
              className={`notification is-${
                notificationMessage.type || 'success'
              } is-light`}
            >
              <button
                onClose
                className="delete"
                onClick={() => setNotifcationMessage({})}
              />
              {notificationMessage.body}
            </div>
          )}
        </div>
      </form>
    </Layout>
  )
}

export default AddArticle
