import React from 'react'
import { Router } from '@reach/router'
import Login from '../../components/Login'
import Layout from '../../components/Layout'
import AddArticle from './members/AddArticle'
import ApproveArticles from './members/ApproveArticles'

const Account = () => (
  <Layout>
    <Router>
      <AddArticle path="/auth/members/AddArticle" />
      <ApproveArticles path="/auth/members/ApproveArticles" />
      <Login path="/auth/*" />
    </Router>
  </Layout>
)

export default Account
